import styles from "./EditDomanda.module.css";
import { useContext, useEffect, useState } from "react";
import GenericButton from "../UI/GenericButton";
import GenericAlternativeButton from "../UI/GenericAlternativeButton";
import Card from "../UI/Card";
import GameContext from "../../context/game-context";
import axios from "axios";

var counter_CORRETTE = 1;
var counter_SBAGLIATE = 1;
var image;
var file;

function EditDomanda(props){
    const game_ctx = useContext(GameContext);
    const websiteUrl = "https://cognicare.altervista.org/uploads/";

    const [totalAnswers_CORRECT, setTotalAnswers_CORRECT] = useState(1);
    const [totalAnswers_WRONG, setTotalAnswers_WRONG] = useState(1);

    const [tipoGiocoModifica, setTipoGiocoModifica] = useState(props.tipoGioco);
    const [categoriaDomandaModifica, setCategoriaDomandaModifica] = useState(props.categoriaDomanda);
    const [validCategory, setValidCategory] = useState(true)
    const [domandaModifica, setDomandaModifica] = useState(props.domanda);
    const [validDomanda, setValidDomanda] = useState(true);
    const [suggerimentoModifica, setSuggerimentoModifica] = useState(props.suggerimento)
    const [ID, setID] = useState(props.ID);

    const [rispCorretta_1Modifica, setRispCorretta_1Modifica] = useState(props.correttaN1);
    const [rispCorretta_2Modifica, setRispCorretta_2Modifica] = useState(props.correttaN2);
    const [rispCorretta_3Modifica, setRispCorretta_3Modifica] = useState(props.correttaN3);
    const [rispCorretta_4Modifica, setRispCorretta_4Modifica] = useState(props.correttaN4);
    const [validCorrette, setValidCorrette] = useState(true);

    const [rispSbagliata_1Modifica, setRispSbagliata_1Modifica] = useState(props.sbagliataN1);
    const [rispSbagliata_2Modifica, setRispSbagliata_2Modifica] = useState(props.sbagliataN2);
    const [rispSbagliata_3Modifica, setRispSbagliata_3Modifica] = useState(props.sbagliataN3);
    const [rispSbagliata_4Modifica, setRispSbagliata_4Modifica] = useState(props.sbagliataN4);
    const [validSbagliate, setValidSbagliate] = useState(true);

    const [imageFile, setImageFile] = useState(websiteUrl.concat(props.immagine));
    const [audioFile, setAudioFile] = useState(websiteUrl.concat(props.immagine));
    const [myFile, setMyFile] = useState(null);
    const [msg, setMsg] = useState("");
    const [flagUpload, setFlagUpload] = useState(1);

    useEffect(() => {
        console.log(myFile);
    }, []);

    function selectFile() {
        image = document.getElementById("mfile").click();
        // setImageFile(image.files[0]);
        // console.log(image);
    }
    function setFile(e) {
        setMyFile(e.target.files[0]);
        console.log(e.target.files[0].name);
        if(e.target.files.length > 0){
            if(tipoGiocoModifica === "QUIZ CON IMMAGINI"){
                setImageFile(URL.createObjectURL(e.target.files[0]));
            }
            if(tipoGiocoModifica === "QUIZ CON SUONI"){
                setAudioFile(URL.createObjectURL(e.target.files[0]));
            }
        }
        // setImageFile(URL.createObjectURL(e.target.files[0]));
        setFlagUpload((prevState) => (prevState + 1))
    }
    function uploadFile(){
        const url="https://cognicare.altervista.org/provaScript.php"
        const data = new FormData();
        data.append("file", myFile);
        axios.post(url, data).then(response => setMsg(response.data)).catch(error => setMsg(error))
    }

    function uploadImage(){
        if(flagUpload !== 1){
            uploadFile();
        }
        // else{
        //     alert("Si è verificato un errore! Riprova tra qualche minuto");
        // }
    }

    useEffect(() => {
        counter_CORRETTE = 1;
        counter_SBAGLIATE = 1;
        if(rispCorretta_2Modifica.trim().length > 0){
            counter_CORRETTE += 1;
        }
        if(rispCorretta_3Modifica.trim().length > 0){
            counter_CORRETTE += 1;
        }
        if(rispCorretta_4Modifica.trim().length > 0){
            counter_CORRETTE += 1;
        }
        if(rispSbagliata_2Modifica.trim().length > 0){
            counter_SBAGLIATE += 1;
        }
        if(rispSbagliata_3Modifica.trim().length > 0){
            counter_SBAGLIATE += 1;
        }
        if(rispSbagliata_4Modifica.trim().length > 0){
            counter_SBAGLIATE += 1;
        }

        setTotalAnswers_CORRECT(counter_CORRETTE);
        setTotalAnswers_WRONG(counter_SBAGLIATE);
        // counter_CORRETTE = props.count_corrette;
        // counter_SBAGLIATE = props.count_sbagliate;
    }, []);

    function domandaChangeHandler(event){
        setDomandaModifica(event.target.value);
    }

    function suggerimentoChangeHandler(event){
        setSuggerimentoModifica(event.target.value)
    }

    function rispostaCorretta_1_ChangeHandler(event){
        setRispCorretta_1Modifica(event.target.value)
    }
    function rispostaCorretta_2_ChangeHandler(event){
        setRispCorretta_2Modifica(event.target.value)
    }
    function rispostaCorretta_3_ChangeHandler(event){
        setRispCorretta_3Modifica(event.target.value)
    }
    function rispostaCorretta_4_ChangeHandler(event){
        setRispCorretta_4Modifica(event.target.value)
    }

    function rispostaSbagliata_1_ChangeHandler(event){
        setRispSbagliata_1Modifica(event.target.value)
    }
    function rispostaSbagliata_2_ChangeHandler(event){
        setRispSbagliata_2Modifica(event.target.value)
    }
    function rispostaSbagliata_3_ChangeHandler(event){
        setRispSbagliata_3Modifica(event.target.value)
    }
    function rispostaSbagliata_4_ChangeHandler(event){
        setRispSbagliata_4Modifica(event.target.value)
    }

    function aggiungiAlternativaCorretta(){
        if(totalAnswers_CORRECT < 4){
            counter_CORRETTE += 1;
            setTotalAnswers_CORRECT(counter_CORRETTE);
        }
        console.log(counter_CORRETTE);
    }

    function rimuoviAlternativaCorretta(){
        if(totalAnswers_CORRECT > 1){
            counter_CORRETTE -= 1;
            setTotalAnswers_CORRECT(counter_CORRETTE);
        }
        console.log(counter_CORRETTE);
    }

    function aggiungiAlternativaSbagliata(){
        if(totalAnswers_WRONG < 4){
            counter_SBAGLIATE += 1;
            setTotalAnswers_WRONG(counter_SBAGLIATE);
        }
        console.log(counter_SBAGLIATE);
    }

    function rimuoviAlternativaSbagliata(){
        if(totalAnswers_WRONG > 1){
            counter_SBAGLIATE -= 1;
            setTotalAnswers_WRONG(counter_SBAGLIATE);
        }
        console.log(counter_SBAGLIATE);
    }

    function verificaInput(){
        let valore_DOMANDA = true;
        let valore_CATEGORIA = true;
        let valore_CORRETTE = true;
        let valore_SBAGLIATE = true;
        let valore_FILE = true;

        if(domandaModifica.trim().length === 0){
            setValidDomanda(false);
            valore_DOMANDA = false;
        }
        else{
            setValidDomanda(true)
            valore_DOMANDA = true
        }

        if(categoriaDomandaModifica.trim().length === 0){
            setValidCategory(false);
            valore_CATEGORIA = false;
        }
        else{
            setValidCategory(true);
            valore_CATEGORIA = true;
        }

        if(tipoGiocoModifica === "QUIZ" || tipoGiocoModifica === "QUIZ CON IMMAGINI"){
            if(rispCorretta_1Modifica.trim().length === 0 && rispCorretta_2Modifica.trim().length === 0 && rispCorretta_3Modifica.trim().length === 0 && rispCorretta_4Modifica.trim().length === 0){
                setValidCorrette(false);
                valore_CORRETTE = false;
            }
            else{
                setValidCorrette(true);
                valore_CORRETTE = true
            }
            if(rispSbagliata_1Modifica.trim().length === 0 && rispSbagliata_2Modifica.trim().length === 0 && rispSbagliata_3Modifica.trim().length === 0 && rispSbagliata_4Modifica.trim().length === 0){
                setValidSbagliate(false);
                valore_SBAGLIATE = false;
            }
            else{
                setValidSbagliate(true)
                valore_SBAGLIATE = true;
            }
        }

        // if(tipoGiocoModifica === "QUIZ CON IMMAGINI"){
        //     if(myFile){
        //         setValidImage(true)
        //         valore_FILE = true
        //     }
        //     else{
        //         setValidImage(false)
        //         valore_FILE = false
        //     }
        // }

        if(tipoGiocoModifica === "COMPLETA LA PAROLA"){
            valore_CORRETTE = true;
            valore_SBAGLIATE = true;
            valore_FILE = true;
        }

        if(!valore_DOMANDA || !valore_CATEGORIA || !valore_CORRETTE || !valore_SBAGLIATE){
            console.log("DOMANDA = " + valore_DOMANDA)
            console.log("CATEGORIA = " + valore_CATEGORIA)
            console.log("CORRETTE = " + valore_CORRETTE)
            console.log("SBAGLIATE = " + valore_SBAGLIATE)
            console.log("FILE = " + valore_FILE)
            return false
        }
        else{
            return true
        }
    }

    function salvaDomanda(){
        let risultato = verificaInput();

        if(risultato){
            var modified_question;
            var correct_answers = {};
            var wrong_answers = {};

            if(rispCorretta_1Modifica.trim().length > 0){
                correct_answers["correct_answer_n1"] = rispCorretta_1Modifica;
            }
            else{
                correct_answers["correct_answer_n1"] = "";
            }
            if(rispCorretta_2Modifica.trim().length > 0){
                correct_answers["correct_answer_n2"] = rispCorretta_2Modifica;
            }
            else{
                correct_answers["correct_answer_n2"] = "";
            }
            if(rispCorretta_3Modifica.trim().length > 0){
                correct_answers["correct_answer_n3"] = rispCorretta_3Modifica;
            }
            else{
                correct_answers["correct_answer_n3"] = "";
            }
            if(rispCorretta_4Modifica.trim().length > 0){
                correct_answers["correct_answer_n4"] = rispCorretta_4Modifica;
            }
            else{
                correct_answers["correct_answer_n4"] = "";
            }
    
            if(rispSbagliata_1Modifica.trim().length > 0){
                wrong_answers["wrong_answer_n1"] = rispSbagliata_1Modifica;
            }
            else{
                wrong_answers["wrong_answer_n1"] = "";
            }
            if(rispSbagliata_2Modifica.trim().length > 0){
                wrong_answers["wrong_answer_n2"] = rispSbagliata_2Modifica;
            }
            else{
                wrong_answers["wrong_answer_n2"] = "";
            }
            if(rispSbagliata_3Modifica.trim().length > 0){
                wrong_answers["wrong_answer_n3"] = rispSbagliata_3Modifica;
            }
            else{
                wrong_answers["wrong_answer_n3"] = "";
            }
            if(rispSbagliata_4Modifica.trim().length > 0){
                wrong_answers["wrong_answer_n4"] = rispSbagliata_4Modifica;
            }
            else{
                wrong_answers["wrong_answer_n4"] = "";
            }
    
            if(tipoGiocoModifica === "QUIZ"){
                modified_question = {
                    domanda: domandaModifica,
                    rispCorrette: correct_answers,
                    rispSbagliate: wrong_answers,
                    ID: ID
                }
            }
    
            if(tipoGiocoModifica === "QUIZ CON IMMAGINI" || tipoGiocoModifica === "QUIZ CON SUONI"){
                var qualeImg;
    
                if(!myFile){
                    qualeImg = props.immagine;
                }
                else{
                    qualeImg = myFile.name;
                }
                modified_question = {
                    domanda: domandaModifica,
                    rispCorrette: correct_answers,
                    rispSbagliate: wrong_answers,
                    immagine: qualeImg,
                    ID: ID
                }
    
                uploadImage();
    
            }
    
            if(tipoGiocoModifica === "COMPLETA LA PAROLA"){
                modified_question = {
                    domanda: domandaModifica.toUpperCase(),
                    rispCorrette: correct_answers,
                    rispSbagliate: wrong_answers,
                    suggerimento: suggerimentoModifica,
                    ID: ID
                }
            }
    
            game_ctx.salvaDomandaModificata(modified_question, ID);
            alert('Domanda modificata con successo!')
            props.chiudiFormModificaDomanda();
        }
        else{
            console.log("ERRORE")
        }        
    }

    return(
        <>
            <h1 className={styles.title_scheda}>Modifica domanda</h1>

            <div className={styles.wrapper_impostazioni_gioco}>
                {/* <div className={styles.wrapper_items}> */}
                    <label className={styles.label_style}>Tipo di gioco</label>
                    <input className={styles.textbox_style_NOT_ALLOWED} type="text" value={tipoGiocoModifica} readOnly></input>
                {/* </div> */}
                {/* <div className={styles.wrapper_items}> */}
                    <label className={styles.label_style}>Categoria domanda</label>
                    <input className={`${styles.textbox_style_NOT_ALLOWED} ${!validCategory ? styles.invalid : ""}`} type="text" value={categoriaDomandaModifica} readOnly></input>
                    {!validCategory && <div style={{width: "100%", color: "red", textAlign: "center"}}>Inserisci la categoria della domanda</div>}
                {/* </div> */}
                
            

                {tipoGiocoModifica === "QUIZ" && 
                    <>
                        <label className={styles.label_style}>Domanda: </label>
                        <input className={`${styles.textbox_style} ${!validDomanda ? styles.invalid : ""}`} type="text" value={domandaModifica} onChange={domandaChangeHandler}></input>
                    </>
                }

                {tipoGiocoModifica === "QUIZ CON IMMAGINI" && 
                    <>
                        <input type="file" name="mfile" id="mfile" onChange={setFile} style={{display: 'none'}}></input>
                        <button onClick={selectFile}>{"Select file"}</button>
                        <img className={styles.preview_image} src={imageFile}></img>
                        <label className={styles.label_style}>Domanda: </label>
                        <input className={`${styles.textbox_style} ${!validDomanda ? styles.invalid : ""}`} type="text" value={domandaModifica} onChange={domandaChangeHandler}></input>
                    </>
                }

                {tipoGiocoModifica === "QUIZ CON SUONI" && 
                    <>
                        <input type="file" name="mfile" id="mfile" onChange={setFile} style={{display: 'none'}}></input>
                        <button onClick={selectFile}>{"Select file"}</button>
                        <audio controls={true} src={audioFile}></audio>
                        <label className={styles.label_style}>Inserisci domanda: </label>
                        <input className={`${styles.textbox_style} ${!validDomanda ? styles.invalid : ""}`} type="text" onChange={domandaChangeHandler}></input>
                    </>
                }

                {tipoGiocoModifica === "COMPLETA LA PAROLA" && 
                    <>
                        <label className={styles.label_style}>Parola da indovinare: </label>
                        <input className={`${styles.textbox_style} ${!validDomanda ? styles.invalid : ""}`} type="text" value={domandaModifica} onChange={domandaChangeHandler}></input>
                        <label className={styles.label_style}>Inserisci suggerimento: </label>
                        <input className={`${styles.textbox_style} ${!validDomanda ? styles.invalid : ""}`} type="text" value={suggerimentoModifica} onChange={suggerimentoChangeHandler}></input>
                    </>
                }
                {!validDomanda && <div style={{width: "100%", color: "red", textAlign: "center"}}>La domanda non può essere vuota</div>}

                {(tipoGiocoModifica === "QUIZ" || tipoGiocoModifica === "QUIZ CON IMMAGINI" || tipoGiocoModifica === "QUIZ CON SUONI") &&
                    <>
                        <div className={styles.wrapper_generico}>
                            <div className={styles.wrapper_items}>
                                <label className={styles.label_style}>Risposta Corretta: </label>
                                <input className={`${styles.textbox_style_RISPOSTE} ${!validCorrette ? styles.invalid : ""}`} type="text" value={rispCorretta_1Modifica} onChange={rispostaCorretta_1_ChangeHandler}></input>

                                {totalAnswers_CORRECT > 1 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Corretta: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validCorrette ? styles.invalid : ""}`} type="text" value={rispCorretta_2Modifica} onChange={rispostaCorretta_2_ChangeHandler}></input>
                                    </>
                                }

                                {totalAnswers_CORRECT > 2 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Corretta: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validCorrette ? styles.invalid : ""}`} type="text" value={rispCorretta_3Modifica} onChange={rispostaCorretta_3_ChangeHandler}></input>
                                    </>
                                }

                                {totalAnswers_CORRECT > 3 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Corretta: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validCorrette ? styles.invalid : ""}`} type="text" value={rispCorretta_4Modifica} onChange={rispostaCorretta_4_ChangeHandler}></input>
                                    </>
                                }
                                {!validCorrette && <div style={{width: "100%", color: "red", textAlign: "center"}}>Devi inserire almeno una risposta corretta</div>}

                                <div className={styles.wrapper_flexible}>
                                    {totalAnswers_CORRECT < 4 &&
                                        <GenericAlternativeButton
                                            onClick={aggiungiAlternativaCorretta}
                                            buttonText={"Aggiungi corretta"}
                                        >
                                        </GenericAlternativeButton>
                                    }
                                    {totalAnswers_CORRECT > 1 &&
                                        <GenericAlternativeButton
                                            onClick={rimuoviAlternativaCorretta}
                                            colore_rosso={true}
                                            buttonText={"Rimuovi corretta"}
                                        >
                                        </GenericAlternativeButton>
                                    }
                                </div>
                                
                            </div>

                            <div className={styles.wrapper_items}>
                                <label className={styles.label_style}>Risposta Sbagliata 1: </label>
                                <input className={`${styles.textbox_style_RISPOSTE} ${!validSbagliate ? styles.invalid : ""}`} type="text" value={rispSbagliata_1Modifica} onChange={rispostaSbagliata_1_ChangeHandler}></input>

                                {totalAnswers_WRONG > 1 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Sbagliata 2: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validSbagliate ? styles.invalid : ""}`} type="text" value={rispSbagliata_2Modifica} onChange={rispostaSbagliata_2_ChangeHandler}></input>
                                    </>
                                }

                                {totalAnswers_WRONG > 2 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Sbagliata 3: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validSbagliate ? styles.invalid : ""}`} type="text" value={rispSbagliata_3Modifica} onChange={rispostaSbagliata_3_ChangeHandler}></input>
                                    </>
                                }

                                {totalAnswers_WRONG > 3 &&
                                    <>
                                        <label className={styles.label_style}>Risposta Sbagliata 4: </label>
                                        <input className={`${styles.textbox_style_RISPOSTE} ${!validSbagliate ? styles.invalid : ""}`} type="text" value={rispSbagliata_4Modifica} onChange={rispostaSbagliata_4_ChangeHandler}></input>
                                    </>
                                }
                                {!validSbagliate && <div style={{width: "100%", color: "red", textAlign: "center"}}>Devi inserire almeno una risposta sbagliata</div>}

                                <div className={styles.wrapper_flexible}>
                                    {totalAnswers_WRONG < 4 &&
                                        <GenericAlternativeButton
                                            onClick={aggiungiAlternativaSbagliata}
                                            buttonText={"Aggiungi sbagliata"}
                                        >
                                        </GenericAlternativeButton>
                                    }
                                    {totalAnswers_WRONG > 1 &&
                                        <GenericAlternativeButton
                                            onClick={rimuoviAlternativaSbagliata}
                                            colore_rosso={true}
                                            buttonText={"Rimuovi sbagliata"}
                                        >
                                        </GenericAlternativeButton>
                                    }
                                </div>
                                
                            </div>  
                            
                        </div>
                        <hr className={styles.horizontal_line}></hr>
                        
                    </>
                }
                <div className={styles.wrapper_flexible}>
                    <GenericButton
                        onClick={salvaDomanda}
                        generic_button={true}
                        buttonText={"Salva modifiche"}
                    >
                    </GenericButton>

                    <GenericButton
                        onClick={props.chiudiFormModificaDomanda}
                        generic_button={true}
                        red_styling
                        buttonText={"Torna alla lista"}
                    >
                    </GenericButton>
                </div>
            </div>
        </>
        
    );
}

export default EditDomanda;